import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import ViewEbankingBalance from "./ViewEbankingBalance";
import ViewEbankingBalanceDetails from "./ViewEbankingBalanceDetails";

export default function ViewEbankingBalanceContainer() {
  const { tabName = "overview" } = useParams();
  const navigate = useNavigate();

  return (
    <Content id="view-ebanking-container">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/ebanking/balance/" + key);
        }}
        items={[
          {
            label: "Overview",
            key: "overview",
            children: <ViewEbankingBalance />,
          },
          {
            label: "Details",
            key: "detail",
            children: <ViewEbankingBalanceDetails />,
          },
        ]}
      />
    </Content>
  );
}
